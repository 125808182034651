.knowledgeContest2022Page{
  background: #003cc2;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 60px;
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  .contestHeader{
    width: 100vw;
    margin-left: -15px;
    margin-right: -15px;
  }
  .contestPanel{
    position: relative;
    z-index: 50;
    background: white;
    display: flex;
    padding: 10px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 20px;
    .contestPanelTitle{
      font-size: 16px;
      padding: 30px 5px;
      font-weight: bold;
      background: #223cc2;
      color: white;
      width: 26px;
      border-radius: 4px;
      margin-right: 15px;
    }
    h4{
      color:#223cc2;
      margin-bottom: 0;
      margin-top: 5px;
      font-size: 14px;
    }
    p{
      margin-top: 5px;
      font-size: 12px;
      color: #3b3b3b;
    }
  }
  .circle01{
    position: absolute;
    transform: rotate(140deg);
    width: 400px;
    right: -100px;
    top: 300px;
    z-index: 1;
  }
  .circle02{
    position: absolute;
    transform: rotate(75deg);
    width: 250px;
    left: -100px;
    top: 780px;
    z-index: 1;
  }
}
