body{
  background: #f6f6f6;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  color: #2c3e50;
}
.za-tab-bar{
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: white;
  height: calc(50px + env(safe-area-inset-bottom))!important;
  height: calc(50px + constant(safe-area-inset-bottom))!important;
}
a{
  color: #E564AC;
  &:active{
    color: #9d3971;
  }
}
p{
  line-height: 22px;
}
.za-keyboard-picker{
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: white;
  .za-keyboard__keys{
    border-bottom: 1px solid rgb(230, 230, 230);
  }
}


.ArticleCard {
  margin-bottom: 10px;
  .header {
    border-bottom: 3px solid #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      color: #9d3971;
      font-size: 22px;
      border-bottom: 3px solid #9d3971;
      font-weight: 800;
      padding: 0 10px 5px 10px;
      margin-bottom: -3px;
    }

    .more {
      font-size: 13px;
      padding-right: 10px;
    }

  }

  .body {
    .article-line {
      padding: 5px 0;
      .normal-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 10px;
        border-bottom: 1px solid #eeeeee;
        padding: 8px 8px;
        .title {
          overflow: hidden;
          font-size: 16px;
          font-weight: bolder;
          line-height: 22px;
        }

        .createdTime {
          width: 100px;
          text-align: right;
          font-size: 12px;
        }
      }

      .first-line {
        display: flex;
        align-items: center;
        .calendar {
          margin-top: -5px;
          width: 80px;
          background: #fcf4f4;
          color: #d3645b;
          font-weight: 500;
          .month {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            height: 25px;
            padding-top: 6px;
          }
          .date {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            height: 40px;
            font-weight: bolder;
          }
          margin-right: 8px;
        }

        .first-line-body {
          flex: 1;
          display: inline-flex;
          flex-direction: column;
          border-bottom: 1px solid #eeeeee;
          min-height: 44px;
          margin-right: 10px;
          font-size: 18px;
          padding: 8px 8px;
          font-weight: bolder;
          .description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            margin-top: 5px;
            font-size: 13px;
          }
        }
      }
    }

  }
}
.PageHeader {
  padding: 8px;
  display: flex;
  border-bottom: 1px solid #E5E5E5;

  .scs-logo {
    padding: 4px 8px;
    width: 50px;
    height: 50px;
    border-right: 1px solid #E5E5E5;
  }

  .header-title-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-logo {
    padding: 4px 8px;
    width: 50px;
    height: 50px;
  }

  .header-title {
    color: #9d3971;
    font-size: 15px;
    font-weight: 500;
    display: block;
  }

  .header-subtitle {
    display: inline-block;
    font-size: 13px;
  }

}
